import React from "react"
import styles from "./showreel.module.scss"
import ReactPlayer from "react-player"
import videoReel from "../constants/videoReel.json"
import { Helmet } from "react-helmet"
import Vimeo from "@u-wave/react-vimeo"
import { Container } from "semantic-ui-react"

function showreel(props) {
  const { locale } = props.pageContext
  return (
    <div>
      <Helmet>
        <title>
          {locale === "en" ? "Showreel - Amplitudo" : "Showreel - Amplitudo"}
        </title>
        <meta
          name="description"
          content={
            locale === "en"
              ? "A collection of what makes us proud. Agencies, adverts, TV, film, web communication. They all come together to represent our creative manifesto and art."
              : "Questa è una raccolta di ciò che ci rende orgogliosi. Agenzie creative, pubblicità, TV, cinema, comunicazione web. Tutto questo insieme compone il nostro manifesto creativo e la nostra arte."
          }
        />
      </Helmet>
      <Container>
        <div className={styles.videoContainer}>
          {/* <ReactPlayer
          className={styles.video}
          url={`https://player.vimeo.com/video/${videoReel.videoId}`}
        /> */}
          {/* <Vimeo
          className={styles.video}
          video={videoReel.videoId}
          width="1200"
        /> */}
          <div className="vimeo-video-container">
            <iframe
              className="vimeo-video"
              src="https://player.vimeo.com/video/678233921"
              frameborder="0"
              allow="autoplay; fullscreen"
              allowfullscreen
            ></iframe>
          </div>
          {/* <script src="https://player.vimeo.com/api/player.js"></script> */}
        </div>
      </Container>
    </div>
  )
}

export default showreel
